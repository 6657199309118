import React from "react"
import Helmet from "react-helmet"
import Footer from "../components/footer"
import Header from "../components/header"

import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children, postedOn, tags }) => {
  
  return (
    <div>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-2HFLPGW34P"></script>
        <script src="/custom-scripts.js" defer type="text/javascript"></script>
      </Helmet>
      <Helmet>
        <script data-ad-client="ca-pub-5231685990974222" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <Helmet>
        <script src="/jquery.min.js" defer type="text/javascript"></script>
        <script src="/bootstrap.min.js" defer type="text/javascript"></script>
      </Helmet>
      <Header location={location} title={title} postedOn={postedOn} tags={tags}/>
      <div className="container-fluid" id="content-wrapper"
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(36),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <main className="container-fluid">
          <div>
            {children}
          </div>
        </main>
      </div>
      <Footer/>
    </div>
  )
}

export default Layout
