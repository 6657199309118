import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query BioQueryFooter {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter{
              link
            }
            github{
              link
            }
            linkedin{
              link
            }
            stacko{
              link
            },
            insta {
              link,
              iconClass
            }
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata

  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="mb-2">LOCATION</h4>
            <p className="pre-wrap lead mb-0">Mumbai, Maharashtra, India</p>
          </div>
          <div className="col-sm-6">
            <h4 className="mb-2">AROUND THE WEB</h4>
            <div style={{marginTop: '15px'}}>
              <a className="footer-social-link d-inline-flex mx-1 text-white" target="_blank" href={social.github.link}>
                <i className="fab fa-github"></i>
              </a>
              <a className="footer-social-link d-inline-flex mx-1 text-white" target="_blank" href={social.twitter.link}>
                <i className="fab fa-twitter"></i>
              </a>
              <a className="footer-social-link d-inline-flex mx-1 text-white" target="_blank" href={social.linkedin.link}>
                <i className="fab fa-linkedin"></i>
              </a>
              <a className="footer-social-link d-inline-flex mx-1 text-white" target="_blank" href={social.stacko.link}>
                <i className="fab fa-stack-overflow"></i>
              </a>
              <a className="footer-social-link d-inline-flex mx-1 text-white" target="_blank" href={social.insta.link}>
                <i className={social.insta.iconClass}></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12" style={{textAlign: 'center'}}>
            Copyright © {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer