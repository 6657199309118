import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"

const Header = ({ location, title, postedOn, tags }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  console.log('postedOn', postedOn)

  let isActive = function(pageRoute){
    return location.pathname == pageRoute
  }

  let isRoot = function() {
    return location.pathname === rootPath
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
        <div className="container">
          <a className="navbar-brand" href="/">Rohit Sharma</a>
          <button className="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <svg className="svg-inline--fa fa-bars fa-w-14 menu-icon-on-device" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-0 mx-lg-1">
                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="/projects">Projects</a>
              </li>
              <li className="nav-item mx-0 mx-lg-1">
                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="/blog">Blog</a>
              </li>
              <li className="nav-item mx-0 mx-lg-1">
                <a className="nav-link py-3 px-0 px-lg-3 rounded" href="/about">About</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="masthead bg-primary text-white text-center">
        <div className="container d-flex align-items-center flex-column">
          {/*{isRoot() ? <img className="masthead-avatar mb-5" src="/avataaars.svg" alt="" /> : ''}*/}
          {
            isRoot() ? <h1 className="masthead-heading text-uppercase mb-0">{title}</h1> :
            <div>
              <h1 className="masthead-heading text-uppercase mb-0" style={{'fontSize': '2rem'}}>{title}</h1>
              {postedOn ? <p>{postedOn}</p> : ''}
              {tags ? <ul style={{listStyle: 'none', listStyleType: 'none', display: 'inline-block'}}>
              {
                tags.map((tag, i) => {
                  return (
                    <li className='badge badge-secondary' style={{float: 'left', margin: '5px'}}>{tag}</li>
                  )
                })
              }
            </ul> : ''}
            </div>
          }
          
          {isRoot() ? 
            <div className="divider-custom divider-light">
              <div className="divider-custom-line"></div>
              <div className="divider-custom-icon">
                <svg className="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                </svg>
              </div>
              <div className="divider-custom-line"></div>
            </div> : ''}
          {isRoot() ? <p className="masthead-subheading font-weight-light mb-0">Ruby and Node Practioner - Father - LFC Fan</p> : ''}
        </div>
      </header>
    </div>
  )
}

export default Header